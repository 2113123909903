<template>
    <transition name="slide-fade">
        <section class="data-table section-gap-30">
            <div class="section-title-wrap">
                <h2 class="section-title">{{ $t("recent jobs") }}</h2>
            </div>
            <TableLoader v-if="isContentLoading"></TableLoader>
            <div class="recent-job-list" v-else>
                <h4 class="empty-message" v-if="isEmpty">{{ $t("no jobs found") }}</h4>
                <div class="table-wrap" v-else>
                    <div class="table">
                        <div class="table__row table__head">
                            <div class="table-cell">{{ $t("Job Title") }}</div>
                            <div class="table-cell">{{ $t("Applicants") }}</div>
                            <div class="table-cell text-center">{{ $t("Status") }}</div>
                            <div class="table-cell text-center">{{ $t("Deadline") }}</div>
                            <div class="table-cell text-center">{{ $t("Actions") }}</div>
                        </div>
                        <div class="table__row" v-for="job in jobs">
                            <div class="table-cell designation">
                                <router-link :to="{ name : 'job.pipeline', params: { jobId: job.slug } }">
                                    {{ job.title }}
                                </router-link>
                            </div>
                            <div class="table-cell applicant-list">
                                <div class="applicant-list__wrap">
                                    <div class="applicants__img" v-if="job.applicant_count > 0">
                                        <img v-for="applicant in job.applicants" :src="applicant.image" alt=""
                                             @error="loadApplicantErrorImage(applicant)"/>
                                        <p class="Applicants" v-if="job.applicant_count > 0">
                                            <router-link :to="{ name : 'job.candidates', params: { jobId: job.slug } }">
                                                {{ job.applicant_count }}
                                            </router-link>
                                        </p>
                                    </div>
                                    <div class="applicants__img" v-else>
                                        <p class="no__applicant">{{ $t("No applicant") }}</p>
                                    </div>
                                    <div class="applicants__number" v-if="showAnalytics">
                                         <v-popover offset="10" :disabled="false" trigger="hover" :auto-hide="false"
                                               style="display:initial;">
                                            <button class="ej__tooltip__button ml-1"> <i class="eicon e-graphic"></i> </button>
                                            <template slot="popover">
                                                <div style="text-align:left;" v-if="jobAnalytics">
                                                    <p class="analytics visits">{{
                                                            $t(`Visitors`)
                                                        }}:  <span> {{ analytics[job.id].visits }} </span></p>
                                                    <p class="analytics unique">{{
                                                            $t(`Unique Visitors`)
                                                        }}:  <span> {{ analytics[job.id].unique_visits }} </span></p>
                                                    <p class="analytics applied">{{
                                                            $t(`Candidates Applied`)
                                                        }}:  <span> {{ analytics[job.id].applied }} </span></p>
                                                    <p class="analytics pending">{{
                                                            $t(`Candidates Pending`)
                                                        }}:  <span> {{ analytics[job.id].pending }} </span></p>
                                                </div>
                                                <div v-else>
                                                    <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
                                                            <defs>
                                                                <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                                                                    <stop stop-color="#597dfc" stop-opacity="0" offset="0%"/>
                                                                    <stop stop-color="#597dfc" stop-opacity=".631" offset="63.146%"/>
                                                                    <stop stop-color="#597dfc" offset="100%"/>
                                                                </linearGradient>
                                                            </defs>
                                                            <g fill="none" fill-rule="evenodd">
                                                                <g transform="translate(1 1)">
                                                                    <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                                                                        <animateTransform
                                                                                attributeName="transform"
                                                                                type="rotate"
                                                                                from="0 18 18"
                                                                                to="360 18 18"
                                                                                dur="0.9s"
                                                                                repeatCount="indefinite"/>
                                                                    </path>
                                                                    <circle fill="#fff" cx="36" cy="18" r="1">
                                                                        <animateTransform
                                                                                attributeName="transform"
                                                                                type="rotate"
                                                                                from="0 18 18"
                                                                                to="360 18 18"
                                                                                dur="0.9s"
                                                                                repeatCount="indefinite"/>
                                                                    </circle>
                                                                </g>
                                                            </g>
                                                        </svg>

                                                </div>

                                            </template>
                                        </v-popover>
                                    </div>
                                    <small class="applicants__number" v-else>({{ job.applicant_count }} {{ $t("Applied")}})</small>

                                </div>
                            </div>
                            <div class="table-cell job__status">
                                <span class="success-label" v-if="!job.is_expired">{{ $t("Active") }}</span>
                                <span class="danger-label" v-else>{{ $t("Expired") }}</span>
                            </div>
                            <div class="table-cell job__deadline">
                                <div class="deadline__text">
                                    <p>{{ job.expire_at }}</p>
                                    <small>({{ job.days_left }})</small>
                                </div>
                            </div>
                            <div class="table-cell author-action">
                                <a v-if="canViewJob" target="_blank" :href="job.job_link" class="social-button semi-button-success"><i
                                        class="eicon e-eye-alt"></i></a>

                                <router-link v-if="canManagementJob" :to="{name: 'job.edit', params: {id: job.slug}}" class="social-button semi-button-primary">
                                    <div class="control-button__icon">
                                        <i class="eicon e-document"></i>
                                    </div>
                                </router-link>

                                <div class="job-share-dashboard" v-if="!job.is_expired">
                                    <a href="javascript:void(0)" class="social-button semi-button-info"><i
                                            class="eicon e-share"></i></a>
                                    <div class="share-button-menu">
                                        <a class="dropdown-item" target="_blank" :href="job.social_links.facebook">
                                            <div class="dropdown-icon"><i class="eicon e-facebook"></i></div>
                                            {{ $t("facebook") }}
                                        </a>
                                        <a class="dropdown-item" target="_blank" :href="job.social_links.linkedIn">
                                            <div class="dropdown-icon"><i class="eicon e-linkedin"></i></div>
                                            {{ $t("linkedin") }}
                                        </a>
                                        <a class="dropdown-item" target="_blank" :href="job.social_links.twitter">
                                            <div class="dropdown-icon"><i class="eicon e-twitter"></i></div>
                                            {{ $t("twitter") }}
                                        </a>
                                        <a class="dropdown-item" @click.prevent="copyUrl(job.job_link)">
                                            <div class="dropdown-icon"><i class="eicon e-paper"></i></div>
                                            {{ $t("Copy Link") }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <pagination :pagination="paginationData" @paginate="loadRecentJobs"></pagination>
                </div>
            </div>
        </section>
    </transition>
</template>
<script>
import client from '../../app/api/Client';
import {SWITCHED_TO_ANOTHER_ACCOUNT} from '../../constants/events';
import {EventBus} from '../../extra/event-bus';
import TableLoader from "../_loaders/_TableLoader";
import Pagination from "../pagination/Pagination";
import {mapState} from "vuex";
import {canManagementJob, canViewJob} from "../../config/permission";

export default {
    components: {TableLoader, Pagination},
    data() {
        return {
            jobs: [],
            paginationData: {},
            isContentLoading: false,
            analytics: []
        }
    },

    methods: {
        async loadRecentJobs(page) {
            this.isContentLoading = true;

            if (typeof page === 'undefined') page = 1;

            try {
                let {data: {data}} = await client().get('/company/recent-jobs?page=' + page);
                this.paginationData = data;
                this.jobs = data.data;
            } catch (e) {
            }
            this.isContentLoading = false;

        },
        async analyticsData() {
            this.analytics = [];
            try {
                let {data: {data}} = await client().get('/company/recent-jobs/analytics');
                this.analytics = data;
            } catch (e) {
            }
        },
        copyUrl(url) {
            _helper.copyText(url);
            this.$toast.success('Copied');
        }
    },
    computed: {
        ...mapState(['user']),
        isEmpty() {
            return this.jobs.length === 0;
        },
        jobAnalytics() {
            return this.analytics.length !== 0;
        },
        showAnalytics() {
            return this.user.package_rules.analytics === 1;
        },
        canViewJob() {
            return canViewJob();
        },
        canManagementJob() {
            return canManagementJob();
        }

    },
    async mounted() {
        EventBus.$on(SWITCHED_TO_ANOTHER_ACCOUNT, (accountType) => {
            if (accountType === 'company') {
                this.loadRecentJobs();
                this.analyticsData()

            }
        });
    },
    async created() {
        await this.loadRecentJobs();
        await this.analyticsData()
    },
    beforeDestroy() {
        EventBus.$off(SWITCHED_TO_ANOTHER_ACCOUNT);
    }
}
</script>
<style scoped>
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
{
    transform: translateX(10px);
    opacity: 0;
}

.analytics {
    position: relative;
    padding-left: 15px;
    color: #212529;
    font-size: 12px;
}

.analytics:not(:last-child) {
    margin-bottom: 5px;
}

.analytics:before {
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    content: '';
}

.analytics.applied:before {
    background: #ff455f;
}

.analytics.visits:before {
    background: #008ffb;
}

.analytics.unique:before {
    background: #01e396;
}

.analytics.pending:before {
    background: #775dd0;
}

.ej__tooltip__button {

    height: 40px;
    width: 40px;
    line-height: 43px;
    font-size: 1rem;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    padding: 0;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    background: rgba(47, 193, 225, 0.15);
    color: #2fc1e1;
    -webkit-transition-property: box-shadow, background, color !important;
    -webkit-transition-property: background, color, -webkit-box-shadow !important;
    transition-property: background, color, -webkit-box-shadow !important;
    transition-property: box-shadow, background, color !important;
    transition-property: box-shadow, background, color, -webkit-box-shadow !important;
}


</style>
